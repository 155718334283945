<template>
  <div class="news_article">
    <section class="course_title page_header_space">
      <div class="w1400 bg-deco">
        <img class="bg-deco-img" src="@/statics/img/index/deco_heart_r.webp" alt="">
      </div>
      <div class="w1100">
        <h3 class="title txt-bold">{{ newsArticle.title }}</h3>
        <div class="course_info">
          <p>發佈日期：{{ year }}年{{ month }}月{{ day }}日</p>
        </div>
      </div>
    </section>

    <section class="article_container w1000">
      <div class="content" v-html="newsArticle.content">
        <!-- <p><span class="title">受 文 者</span><span> : </span>{{ newsArticle.unit }}</p>
        <p>
          <span class="title">發文時間</span><span> : </span>
          中華民國{{ year }}年{{ month }}月{{ day }}日
        </p>
        <p>
          <span class="title">主 旨</span><span> : </span>
          {{ newsArticle.content }}
        </p>
        <p>
          <span class="title">相關連結</span><span> : </span>
          <a
            :href="newsArticle.relatedLink"
            target="_blank"
            rel="noreferrer noopener"
          >{{ newsArticle.relatedLink }}</a>
        </p>
        <img :src="newsArticle.files[0].path" alt=""> -->
      </div>
      <div class="image_box" v-if="newsArticle.images && newsArticle.images.length > 0">
        <!-- <h4 class="txt-bold">圖片專區</h4> -->
        <img
          v-for="image in newsArticle.images"
          :key="image.uuid"
          :src="`${API_URL}${image.path}`"
          :alt="image.name"
        >
      </div>

      <div class="video" v-if="newsArticle.json && newsArticle.json.url">
        <h4 class="txt-bold">影音專區</h4>
        <div class="video-container">
          <iframe
            :src="`https://www.youtube.com/embed/${videoID}`"
            frameborder="0"
            allow="accelerometer;
            autoplay;
            clipboard-write;
            encrypted-media;
            gyroscope;
            picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="files" v-if="newsArticle.files && Object.keys(newsArticle.files).length > 0">
        <h4 class="txt-bold">附件下載</h4>
        <p v-for="file in newsArticle.files" :key="file.uuid">
          <a
            :href="`${API_URL}/${file.path}`"
            download
            target="_blank"
            v-text="file.title"
          />
        </p>
      </div>
      <div class="btn_wrap">
        <router-link :to="`/news/${$route.params.type}`" class="btn-return">返回</router-link>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment';
import { articleView } from '@/lib/http';
import { API_URL } from '@/lib/config';

export default {
  name: 'NewsArticle',
  data() {
    return {
      API_URL,
      newsID: '',
      newsArticle: {
        // title: '本會預計3/1(日)將轉換系統網站!',
        // date: '2020-03-27',
        // category: '相關公文',
        // unit: '台灣居家醫療醫學會',
        // content: '檢送美國食品藥物管理局(以下簡稱US FDA)就「腹腔鏡動力絞碎器用於子宮瘤相關手術」發布之最新安全訊息(詳如附件)，請惠予轉知所屬會員，請查照。',
        // relatedLink: 'https://work.howdesign.com.tw/twhomecare_official/news_article.php',
        // img: newsArticleImg,
        // video: 'https://www.youtube.com/embed/zUXiI6iKgiQ',
        // downloadArray: [
        //   {
        //     id: 1,
        //     link: '',
        //     name: '腹腔鏡動力絞碎器用於子宮瘤相關手術',
        //   },
        // ],
      },
    };
  },
  created() {
    this.newsID = this.$route.query.news_id;
    articleView({
      uuid: this.newsID,
    }).then((result) => {
      this.newsArticle = result.data.result.data;
    });
  },
  computed: {
    year() {
      if (this.newsArticle && this.newsArticle.start_date) {
        return moment(this.newsArticle.start_date).format('YYYY') - 1911;
      }
      return '';
    },
    month() {
      if (this.newsArticle && this.newsArticle.start_date) {
        return moment(this.newsArticle.start_date).format('MM');
      }
      return '';
    },
    day() {
      if (this.newsArticle && this.newsArticle.start_date) {
        return moment(this.newsArticle.start_date).format('DD');
      }
      return '';
    },
    videoID() {
      let target = '';
      if (this.newsArticle && this.newsArticle.json) {
        target = this.newsArticle.json.url.split('/').slice(-1).pop();
      }
      return target;
    },
  },
};
</script>
